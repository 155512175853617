export const extractOrganisationSubdomainFromURL = (location) => {
  if (!location || !location.host) {
    return undefined;
  }

  const splitHost = location.host.split(".");

  if (isLocalhostUrl(splitHost)) {
    if (splitHost.length >= 2) {
      return splitHost[0];
    }

    return null;
  }

  if (isDevOrUATUrl(splitHost)) {
    if (splitHost.length >= 4) {
      return splitHost[0];
    }

    return null;
  }

  // Must be a production URL
  if (splitHost.length >= 3) {
    const subdomain = splitHost[0];

    if (subdomain !== "www") {
      return subdomain;
    }

    return null;
  }

  return null;
};

const isLocalhostUrl = (splitHost) => {
  const lastElement = splitHost[splitHost.length - 1].toLowerCase();
  return lastElement.indexOf("localhost") > -1;
};

const isDevOrUATUrl = (splitHost) => {
  const isDevOrUAT =
    splitHost[splitHost.length - 2].toLowerCase() === "vidatec" &&
    splitHost[splitHost.length - 1].toLowerCase().indexOf("dev") > -1;

  return isDevOrUAT;
};
