/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react/macro";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./Button.scss";
import withButtonSubdomainStyling from "./WithButtonSubdomainStyling";

const Button = ({
  children,
  className,
  type,
  onClick,
  size,
  colour,
  css,
  "data-cy": cypressId,
  naked,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`btn__basic ${className} ${size} ${colour} ${
        naked ? "--naked" : ""
      }`}
      data-cy={cypressId}
      css={css}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  children: undefined,
  className: "",
  type: "button",
  onClick: () => {},
  "data-cy": "",
  size: "",
  colour: "primary",
  naked: false,
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  "data-cy": PropTypes.string,
  size: PropTypes.string,
  colour: PropTypes.string,
  css: PropTypes.shape({}),
  naked: PropTypes.bool,
};

export default withButtonSubdomainStyling(Button);
