import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Form } from "react-bootstrap";
import FormError from "./FormError";
import { Label } from "../label/Label";

import "./TextInputField.scss";

export const TextInputField = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    errors,
    className,
    placeholder,
    onChange,
    value,
    type,
    "data-cy": cypressId,
  } = props;

  const [id] = useState(_.uniqueId(""));
  const inputClasses = errors[name] ? "error" : "";
  let errorMessage;

  if (errors[name]) {
    if (errors[name].type === "required") {
      errorMessage = "This field is required";
    } else {
      errorMessage = errors[name].message;
    }
  }

  return (
    <div className={`components__form-text-input-field ${className}`}>
      <Label htmlFor={id} text={label} />
      <Form.Control
        id={id}
        type={type}
        name={name}
        value={value}
        className={inputClasses}
        ref={ref}
        placeholder={placeholder}
        data-cy={cypressId}
        onChange={onChange}
      />
      {errorMessage && <FormError message={errorMessage} />}
    </div>
  );
});

TextInputField.displayName = "TextInputField";

TextInputField.defaultProps = {
  type: "text",
  className: "",
  placeholder: "",
  maxLength: -1,
  "data-cy": undefined,
  onChange: () => {},
  value: undefined,
  errors: {},
};

TextInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({}),
  ]),
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  "data-cy": PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
