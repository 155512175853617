import React, { useState, useEffect } from "react";

import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import { NavLink } from "./NavLink";
import DownCaret from "../../../assets/down-caret.svg";

// we create a custom toggler for this component,
// mostly so as we get the functionality from bootstrap but
// not the styles to fight with
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="nav__top-level-link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <img className="nav__down-caret" src={DownCaret} alt="down caret" />
  </button>
));

CustomToggle.displayName = "CustomToggle";

CustomToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

CustomMenu.displayName = CustomMenu;
CustomMenu.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  "aria-labelledby": PropTypes.string,
};

const DropDownItem = ({ children }) => (
  <Dropdown.Item className={"nav__drop-down-item"} as={"button"}>
    {children}
  </Dropdown.Item>
);

DropDownItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const NavDropDown = ({ label, subLinks, active, icon, cypressId }) => {
  const Icon = icon;
  return (
    <Dropdown className={active && "active"}>
      <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle}>
        {icon && <span className="nav__link-icon">{<Icon />}</span>}
        <span data-cy={cypressId}>{label}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {subLinks.map((link) => (
          <NavLink
            key={link.id}
            wrapper={DropDownItem}
            role={"button"}
            className={"nav__sub-link"}
            activities={link.activities}
            to={link.to}
            label={link.label}
            cypressId={link.cypressId}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

NavDropDown.defaultProps = {
  cypressId: "",
};

NavDropDown.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.func,
  subLinks: PropTypes.arrayOf(PropTypes.shape({})),
  active: PropTypes.bool,
  navToggler: PropTypes.bool,
  cypressId: PropTypes.string,
};
