import React from "react";

const Chevron = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Chevron 2 Blue</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="filter-1">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.258824 0 0 0 0 0.400000 0 0 0 1.000000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Chevron-2-Blue"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" filter="url(#filter-1)">
          <g transform="translate(11.071068, 8.000000) rotate(45.000000) translate(-11.071068, -8.000000) translate(6.071068, 3.000000)">
            <rect
              id="Rectangle"
              stroke="#979797"
              fill="#D8D8D8"
              x="0.5"
              y="8.83333333"
              width="9"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#979797"
              fill="#D8D8D8"
              x="0.5"
              y="0.5"
              width="1"
              height="9"
              rx="0.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Chevron;
