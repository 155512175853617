import React from "react";
import PropTypes from "prop-types";
import ExternalLinkButton from "../../core/link/external-link-button/ExternalLinkButton";
import { Logo } from "../../context-aware/logo/Logo";

import "./RegistrationResponseWidget.scss";
export const RegistrationResponseWidget = ({
  href,
  heading,
  bodyText,
  icon,
}) => {
  return (
    <div className="container">
      <div className="regstration-response-widget__page-wrapper">
        <div className="logo-wrapper">
          <Logo />
        </div>
        <h5 data-cy="header">{heading}</h5>
        <p data-cy="body-text">{bodyText}</p>
        {icon && (
          <img
            className="registration-response-widget__icon"
            src={icon}
            alt="response icon"
          />
        )}
        <ExternalLinkButton
          className="return-to-home large"
          data-cy="return-to-sign-in-button"
          href={href}
        >
          Return to Sign In
        </ExternalLinkButton>
      </div>
    </div>
  );
};

RegistrationResponseWidget.defaultProps = {
  icon: "",
};

RegistrationResponseWidget.propTypes = {
  href: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
