import React from "react";
import "./ForgottenPasswordPage.scss";

import FooterImg from "../../../assets/tol-logo-grey-with-title.png";
import { ResetPasswordForm } from "../../../components/authentication/reset-password-form/ResetPasswordForm";
import InternalLink from "../../../components/core/link/internal-link/InternalLink";
import authenticationService from "../../../services/authentication/authentication-service";
import { Logo } from "../../../components/context-aware/logo/Logo";
import { useHistory } from "react-router-dom";

export const ForgottenPasswordPage = () => {
  const history = useHistory();

  const requestPasswordReset = async ({ email }) => {
    await authenticationService.resetPassword(email);
    return history.push("/forgotten-password/link-sent", { email });
  };

  return (
    <div className="pages__forgotten-password-page container-fluid">
      <div className="logo-wrapper">
        <Logo />
      </div>

      <div className="header-and-subtext">
        <h5>Reset Your Password</h5>
        <p>
          Enter the email address associated with your account below and we will
          email you instructions on how to reset your password.
        </p>
      </div>

      <ResetPasswordForm onComplete={requestPasswordReset} />

      <div className="return-to-sign-in-wrapper">
        <InternalLink to="/login" data-cy="return-to-sign-in-link">
          <div className="return-to-sign-in-text">Return to Sign In</div>
        </InternalLink>
      </div>

      <div className="forgotten-password__footer-logo-container">
        <img
          src={FooterImg}
          className="forgotten-password__footer-logo"
          alt="tol logo with log"
        />
      </div>
    </div>
  );
};
