import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ForgottenPasswordLinkSentPage } from "../pages/authentication/forgotten-password-page/ForgottenPasswordLinkSentPage";
import { ForgottenPasswordPage } from "../pages/authentication/forgotten-password-page/ForgottenPasswordPage";
import { NewPasswordPage } from "../pages/authentication/new-password-page/NewPasswordPage";
import { CompleteRegistrationPage } from "../pages/registration/complete-registration/CompleteRegistrationPage";
import { InvalidRegistrationTokenPage } from "../pages/registration/complete-registration/InvalidRegistrationTokenPage";
import { RegistrationCompletePage } from "../pages/registration/complete-registration/RegistrationCompletePage";
import { LoginPage } from "../pages/authentication/login-page/LoginPage";
import { PasswordSuccessfullyResetPage } from "../pages/authentication/password-successfully-reset-page/PasswordSuccessfullyResetPage";
import { InvalidPasswordResetTokenPage } from "../pages/authentication/new-password-page/InvalidPasswordResetTokenPage";
import InternalLinkButton from "../components/core/link/internal-link-button/InternalLinkButton";
export const LoggedOutRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route
        exact
        path="/forgotten-password"
        component={ForgottenPasswordPage}
      />
      <Route
        exact
        path="/forgotten-password/link-sent"
        component={ForgottenPasswordLinkSentPage}
      />
      <Route exact path="/new-password" component={NewPasswordPage} />
      <Route
        exact
        path="/complete-registration"
        component={CompleteRegistrationPage}
      />
      <Route
        exact
        path="/invalid-registration-token"
        component={InvalidRegistrationTokenPage}
      />
      <Route
        exact
        path="/registration-completed"
        component={RegistrationCompletePage}
      />
      <Route
        exact
        path="/password-reset-success"
        component={PasswordSuccessfullyResetPage}
      />
      <Route
        exact
        path="/invalid-password-reset-token"
        component={InvalidPasswordResetTokenPage}
      />
      {/**
       *
       * The below is to allow users to navigate from inside the app
       * the app will install and default to the home page
       * there is no ui, only app based navgation is allowed when installeds
       * This will be replaced when there is public content.
       */}
      <Route exact path="/">
        <div
          style={{ height: "100vh" }}
          className="
          container
          d-flex
          justify-content-center
          align-items-center
          "
        >
          <InternalLinkButton
            type="submit"
            size="large"
            data-cy="log-out-button"
            to={"/login"}
          >
            To Login Page
          </InternalLinkButton>
        </div>
      </Route>
    </Switch>
  );
};
