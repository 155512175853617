/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react/macro";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import PropTypes from "prop-types";
import "./Nav.scss";
import Brand from "../../../assets/tol-logo.svg";
import Cross from "../../../assets/cross.svg";
import MenuSettings from "../../../components/core/icon/icons/MenuSettings";
import MenuJourney from "../../../components/core/icon/icons/MenuJourney";
import { NavLinks } from "./NavLinks";
import WithNavSubdomainStyling from "./WithNavSubdomainStyling";

const navSchema = [
  {
    id: 1,
    icon: MenuJourney,
    activities: [],
    to: "/settings",
    label: "My Journey",
    subLinks: [
      {
        id: 2,
        activities: [],
        to: "/chapters",
        label: "Chapters",
      },
      {
        id: 3,
        activities: [],
        to: "/resources",
        label: "Resources",
      },
      {
        id: 4,
        activities: [],
        to: "/updates",
        label: "Something's Changed",
      },
    ],
  },
  {
    id: 5,
    icon: MenuSettings,
    activities: [],
    to: "/settings",
    label: "Settings",
  },
];

const NavDesktop = ({ navToggler, brand }) => {
  return (
    <div className="nav__desktop">
      <div className="container-fluid">
        <div className="nav__desktop-brand">
          <Link to={"/"}>
            <img src={brand} alt="navigation brand" />
          </Link>
        </div>
        <div className="nav__desktop-links">
          {navSchema.map((navLink) => {
            return (
              <NavLinks
                key={navLink.label}
                to={navLink.to}
                icon={navLink.icon}
                activities={navLink.activities}
                label={navLink.label}
                subLinks={navLink.subLinks}
                navToggler={navToggler}
                cypressId={navLink.cypressId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

NavDesktop.propTypes = {
  navToggler: PropTypes.bool,
  brand: PropTypes.string,
};

const NavMobile = ({ navToggler }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [navToggler]);

  return (
    <>
      <div className="nav__mobile">
        <div className="container-fluid">
          <button
            type="button"
            className="nav__mobile-hamburger"
            onClick={() => setOpen(!open)}
          >
            <span className="nav__mobile-burger-slice"></span>
            <span className="nav__mobile-burger-slice"></span>
            <span className="nav__mobile-burger-slice"></span>
          </button>
        </div>
        <div className={`nav__mobile-menu ${open ? "--open" : ""}`}>
          <div className="container-fluid">
            <div className="nav__mobile-bar">
              <button
                type="button"
                className="nav__mobile-close"
                onClick={() => setOpen(!open)}
              >
                <img src={Cross} alt="navigation close button" />
              </button>
            </div>
          </div>
          {navSchema.map((navLink) => {
            return (
              <NavLinks
                key={navLink.label}
                to={navLink.to}
                icon={navLink.icon}
                activities={navLink.activities}
                label={navLink.label}
                subLinks={navLink.subLinks}
                navToggler={navToggler}
                isMobile
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

NavMobile.propTypes = {
  navToggler: PropTypes.bool,
};

const Nav = ({ css }) => {
  const [newPageLoad, updateNewPageLoad] = useState(false);
  const [brand, updateBrand] = useState(undefined);

  const location = useLocation();
  const { subdomainSettings } = useContext(GlobalContext);

  // this effect will fire whenever the link changes
  // true or false / doesn't matter, we just want to make sure that there's
  // a prop we can be [dependant] on to reset our navs
  // we do this up here, because further down our components will loop and
  // process this change more often
  useEffect(() => {
    updateNewPageLoad(!newPageLoad);
  }, [location]);

  // we only want to run this effect when the context is loading
  useEffect(() => {
    if (subdomainSettings && subdomainSettings.logoUrl) {
      updateBrand(subdomainSettings.logoUrl);
    } else {
      updateBrand(Brand);
    }
  }, []);

  return (
    <>
      <nav css={css}>
        <NavDesktop navToggler={newPageLoad} brand={brand} />
        <NavMobile navToggler={newPageLoad} />
      </nav>
      <div className="nav__fixed-height" />
    </>
  );
};

Nav.propTypes = {
  css: PropTypes.shape({}),
};

export default WithNavSubdomainStyling(Nav);
