import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../components/core/button/Button";

export const HomePage = () => {
  const history = useHistory();

  const redirectToLogoutPage = () => {
    history.push("/logout");
  };

  return <div data-cy="login-text"></div>;
};
