/* eslint-disable react/no-unknown-property */
/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react/macro";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../../../context/GlobalContext";

const Icon = ({ type }) => {
  let SvgIcon;

  try {
    SvgIcon = require(`./icons/${type}`);
  } catch (error) {
    throw new Error(
      `The icon requested (${type}) does not have a corresponding svg jsx file.  Please create one.`
    );
  }

  const { subdomainSettings } = useContext(GlobalContext);

  let style;

  if (subdomainSettings && subdomainSettings.primaryColour) {
    let styling;

    try {
      styling = require(`./styles/${type}`);
    } catch (error) {
      throw new Error(
        `The icon requested (${type}) does not have a corresponding subdomain styling file.  Please create one.`
      );
    }

    style = styling.default(subdomainSettings);
  }

  return (
    <div css={style}>
      <SvgIcon.default />
    </div>
  );
};

Icon.defaultProps = {
  css: {},
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  css: PropTypes.shape({}),
};

export default Icon;
