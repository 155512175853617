import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Logo } from "../../../components/context-aware/logo/Logo";
import FooterImg from "../../../assets/tol-logo-grey-with-title.png";
import successImg from "../../../assets/pw-reset-success.svg";
import { useHistory } from "react-router-dom";

import qs from "qs";
import Button from "../../../components/core/button/Button";
import ExternalLinkButton from "../../../components/core/link/external-link-button/ExternalLinkButton";

import "./PasswordSuccessfullyResetPage.scss";

export const PasswordSuccessfullyResetPage = () => {
  const [signInLink, updateSignInLink] = useState("/login");

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }
  }, [updateSignInLink, location.search]);

  return (
    <div className="pages__password-reset-success-page">
      <div className="logo-wrapper">
        <Logo />
      </div>

      <div className="success-img-wrapper">
        <img src={successImg} alt="password reset successfully image" />
      </div>
      <div className="header-and-subtext">
        <h5 data-cy="header">Password Reset</h5>
        <div>Your password has been reset successfully.</div>
      </div>

      <ExternalLinkButton
        size="large"
        className="return-to-sign-in-wrapper"
        data-cy="return-to-sign-in-button"
        href={signInLink}
      >
        Return to Sign In
      </ExternalLinkButton>

      <div className="password-reset-success__footer-logo-container">
        <img
          src={FooterImg}
          className="password-reset-success__footer-logo"
          alt="tol logo with log"
        />
      </div>
    </div>
  );
};

PasswordSuccessfullyResetPage.propTypes = {
  location: PropTypes.object,
};
