import React from "react";

const BackArrow = () => {
  return (
    <svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Arrow" transform="translate(-4.000000, -1.000000)">
          <g
            id="Group"
            transform="translate(11.071068, 8.000000) rotate(45.000000) translate(-11.071068, -8.000000) translate(6.071068, 3.000000)"
          >
            <rect
              id="Rectangle"
              stroke="#D18483"
              fill="#C68785"
              x="0.5"
              y="8.83333333"
              width="9"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#C68785"
              fill="#BD8A87"
              x="0.5"
              y="0.5"
              width="1"
              height="9"
              rx="0.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BackArrow;
