import React from "react";

import InternalLinkButton from "../../components/core/link/internal-link-button/InternalLinkButton";

import "./SettingsPage.scss";
import { Footer } from "../../components/core/footer/Footer";
import { SettingsField } from "./components/SettingsField";
import { PasswordPlaceholder } from "./components/PasswordPlaceholder";
import { InstallPwa } from "../../components/core/pwa/install-pwa/InstallPwa";

export const SettingsPage = () => {
  return (
    <div className="pages__settings-page content-wrapper">
      <div className="page-content">
        <div className="header-wrapper">
          <h2>Settings</h2>
        </div>
        <div className="settings-form-fields">
          <SettingsField
            label="Password"
            onEditTo="/change-password"
            placeholder={<PasswordPlaceholder />}
          />
        </div>
        <div className="container">
          <InstallPwa className="settings-install-pwa" />
        </div>
        <div className="log-out-button-wrapper">
          <InternalLinkButton
            type="submit"
            size="large"
            data-cy="log-out-button"
            to={"/logout"}
          >
            Log Out
          </InternalLinkButton>
        </div>
      </div>
      <Footer />
    </div>
  );
};
