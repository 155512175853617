import { css } from "@emotion/react/macro";

const getStyling = (subdomainSettings) => {
  return css`
    #Rectangle {
      stroke: ${subdomainSettings.primaryColour};
    }
  `;
};

export default getStyling;
