// the beforeinstallprompt event is only supported in some browsers
//https://developer.mozilla.org/en-US/docs/Web/API/Window/onbeforeinstallprompt

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./InstallPwa.scss";
import Button from "../../button/Button";
import Add from "../../../../assets/add.svg";
import PWAPrompt from "react-ios-pwa-prompt";

export const InstallPwa = ({ className }) => {
  const [displayBanner, updateDisplayBanner] = useState("none");
  const [dfp, updateDp] = useState(undefined);

  // add an event listener for the beforeinstall prompt event
  // this only occurs after a serice worker is registered and the manifest.json file
  // has the required fields for the given browser.
  // if the app is already installed, the prompt will not show
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    // Stash the event so it can be triggered later.
    // Update UI to notify the user they can add to home screen
    updateDisplayBanner("flex");
    updateDp(e);
  });

  const handleShowPrompt = (deferredPrompt) => {
    if (!deferredPrompt) {
      console.warn("No deferred prompt set in Install Pwa");
      return;
    }

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
        // if the user selects to add the app,
        // lets get shot of the button and clear out the event
        // we stored in state
        updateDisplayBanner("none");
        updateDp(undefined);
      } else {
        // if they decline, we'll leave the event and the button
        // they might change their mind later
        console.log("User dismissed the A2HS prompt");
      }
      updateDisplayBanner("none");
      updateDp(undefined);
    });
  };

  return (
    <div style={{ display: displayBanner }} className={`${className}`}>
      <div className="install-pwa">
        <p>You can add this app to your home screen by using the link below.</p>
        <Button
          className={`install-pwa__button`}
          onClick={() => handleShowPrompt(dfp)}
          naked
        >
          <span>
            <img src={Add} alt="add icon" /> Add to Home Screen
          </span>
        </Button>
      </div>
    </div>
  );
};

InstallPwa.defaultProps = {
  className: "",
};

InstallPwa.propTypes = {
  className: PropTypes.string,
};

export const InstallPromptForApple = () => (
  <PWAPrompt
    copyShareButtonLabel="1) Press the 'Share' button on the menu bar."
    promptOnVisit={1}
    timesToShow={1}
    copyClosePrompt="X"
    permanentlyHideOnDismiss={false}
  />
);
