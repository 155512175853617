import React, { useState, useContext } from "react";

import { NewPasswordForm } from "../../../components/authentication/new-password-form/NewPasswordForm";
import userService from "../../../services/user/user-service";
import { GlobalContext } from "../../../context/GlobalContext";
import { Footer } from "../../../components/core/footer/Footer";
import InternalLink from "../../../components/core/link/internal-link/InternalLink";
import { useHistory } from "react-router-dom";

import "./ChangePasswordPage.scss";
import HttpStatus from "http-status-codes";
import ErrorCodes from "../../../lib/error-codes";
import { Modal } from "../../../components/core/modal/Modal";

import PasswordResetSuccessfulImg from "../../../assets/pw-reset-success.svg";
import Icon from "../../../components/core/icon/Icon";

export const ChangePasswordPage = () => {
  const { user } = useContext(GlobalContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(undefined);
  const history = useHistory();

  const displayValidationError = (validationError) => {
    if (
      validationError.data.field === "password" &&
      validationError.data.code === ErrorCodes.PASSWORD_DOESNT_MEET_REQUIREMENTS
    ) {
      // If the error is one we are expecting, display the message accordingly.
      setChangePasswordError(
        "The password does not meet the required complexity requirements."
      );
    } else if (
      validationError.data.field === "password" &&
      validationError.data.code === ErrorCodes.INVALID_CREDENTIALS
    ) {
      setChangePasswordError("The current password is incorrect.");
    } else {
      // If the error is not one we are expecting, then bubble the error up the stack
      throw validationError;
    }
  };

  const newPasswordSet = async (newPassword, currentPassword) => {
    setChangePasswordError(undefined);

    try {
      const newPasswordSetResponse = await userService.updatePassword(
        user.id,
        currentPassword,
        newPassword
      );

      setShowSuccessModal(true);
    } catch (updatePasswordError) {
      if (updatePasswordError.statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
        // If the error is a validation error, the display a message accordingly
        return displayValidationError(updatePasswordError);
      } else {
        // If the error is not a validation error, then bubble the error up the stack
        throw updatePasswordError;
      }
    }
  };

  return (
    <>
      <div className="pages__change-password-page content-wrapper">
        <div className="page-content">
          <div className="header-section">
            <h2>Change Password</h2>
            <InternalLink className="back-button" to="/settings">
              <Icon type="BackArrow" />
              <span className="back-text">Back</span>
            </InternalLink>
          </div>
          <div className="new-password-form container">
            <NewPasswordForm
              requireConfirmCurrentPassword
              onComplete={newPasswordSet}
              submitButtonText={"Change Password"}
              errorText={changePasswordError}
            />
          </div>
        </div>

        <Footer />
      </div>
      <Modal
        show={showSuccessModal}
        icon={PasswordResetSuccessfulImg}
        heading="Password Reset"
        bodyText="Your password has been reset successfully"
        primaryCtaText="Return to Settings"
        onPrimaryCtaClicked={() => history.push("/settings")}
      />
    </>
  );
};
