/** @jsxImportSource @emotion/react */

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { LoginForm } from "../../../components/authentication/login-form/LoginForm";
import "./LoginPage.scss";

import { Logo } from "../../../components/context-aware/logo/Logo";
import { GlobalContext } from "../../../context/GlobalContext";
import AuthenticationError from "../../../lib/errors/authentication-error";
import InternalLink from "../../../components/core/link/internal-link/InternalLink";
import BackDropImg from "../../../assets/baby-feet.png";
import LogoOverlay from "../../../assets/tol-text-big-and-bold.png";
import FooterImg from "../../../assets/tol-logo-grey-with-title.png";

export const LoginPage = () => {
  const [loginError, setLoginError] = useState(undefined);
  const [logo, updateLogo] = useState(undefined);
  const { login, subdomainSettings, subDomainSlug, loading } = useContext(
    GlobalContext
  );
  const history = useHistory();

  const performLogin = async ({ email, password }) => {
    setLoginError(undefined);

    try {
      await login(email, password, subDomainSlug);
      return history.push("/settings");
    } catch (error) {
      if (error instanceof AuthenticationError) {
        setLoginError("Incorrect email or password.");
      } else {
        throw error;
      }
    }
  };

  return (
    <div className="pages__login-page container-fluid">
      <div className="row">
        <div className="left-panel">
          <div className="login-page__form">
            <div className="pages__login-inner-wrapper">
              <div className="logo-wrapper">
                <Logo />
              </div>
              <h3 className="login-page__heading"> Sign In </h3>
              <LoginForm onComplete={performLogin} loginError={loginError} />
              <div className="forgotten-password-wrapper">
                <InternalLink to="/forgotten-password">
                  Forgotten Password?
                </InternalLink>
              </div>
            </div>
            <div className="login-page__footer-logo-container">
              <img
                src={FooterImg}
                className="login-page__footer-logo"
                alt="tol logo with log"
              />
            </div>
          </div>
        </div>

        <div className="right-panel">
          <img src={LogoOverlay} className="login-form__panel-overlay" />
          <img
            src={BackDropImg}
            className="login-page__panel-img"
            alt="white vidatec logo"
          />
        </div>
      </div>
    </div>
  );
};
