import AuthenticationError from "../../lib/errors/authentication-error";
import HTTPError from "../../lib/errors/http-error";
import api from "../../lib/http";

export default {
  login: async (email, password, domain) => {
    const response = await api(
      "post",
      "auth/login",
      { email, password, organisationSlug: domain },
      false,
      false,
      true
    );

    if (!response.ok) {
      if (response.status === 401) {
        throw new AuthenticationError();
      } else {
        throw new HTTPError(response.status, response.data);
      }
    }

    return response;
  },
  resetPassword: async (email) => {
    const result = await api(
      "post",
      "auth/password-reset",
      { email },
      true,
      false,
      true
    );
    return result.data;
  },
  newPassword: async (token, password) => {
    const result = await api(
      "post",
      "auth/new-password",
      { token, password },
      true,
      false,
      true
    );
    return result.data;
  },
  validatePasswordResetToken: async (token) => {
    const result = await api(
      "get",
      `auth/validate-password-reset-token?token=${token}`,
      null,
      true,
      false,
      true
    );
    return result.data;
  },
};
