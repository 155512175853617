import SessionError from "../../lib/errors/session-error";
import HTTPError from "../../lib/errors/http-error";
import api from "../../lib/http";

export default {
  session: async () => {
    const response = await api("post", "user/session", {}, false, false, true);

    if (!response.ok) {
      if (response.status === 401) {
        throw new SessionError();
      } else {
        throw new HTTPError(response.status, response.data);
      }
    }
    return response;
  },
  updatePassword: async (userId, currentPassword, newPassword) => {
    const result = await api(
      "post",
      `user/${userId}/password`,
      { currentPassword, newPassword },
      true
    );
    return result;
  },
};
