import React from "react";
import PropTypes from "prop-types";

import "./Modal.scss";
import Button from "../button/Button";
import BootstrapModal from "react-bootstrap/Modal";

export const Modal = ({
  show,
  icon,
  heading,
  bodyText,
  primaryCtaText,
  onPrimaryCtaClicked,
  onClose,
  backdrop,
}) => {
  return (
    <BootstrapModal
      centered
      className="components__modal"
      show={show}
      onHide={onClose}
      backdrop={backdrop}
    >
      <BootstrapModal.Header className="modal-header">
        <BootstrapModal.Title className="modal-title">
          <img className="modal-icon" src={icon} />
          <div>{heading}</div>
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className="modal-body">
        {bodyText}
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="modal-buttons">
        <Button onClick={onPrimaryCtaClicked} size="large">
          {primaryCtaText}
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

Modal.defaultProps = {
  backdrop: undefined,
  onClose: () => {},
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  primaryCtaText: PropTypes.string.isRequired,
  onPrimaryCtaClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  backdrop: PropTypes.string,
};
