import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Cookie from "universal-cookie";
import { extractOrganisationSubdomainFromURL } from "../lib/location-utils";
import authenticationService from "../services/authentication/authentication-service";
import settingsService from "../services/settings/settings-service";
import usersService from "../services/user/user-service";
import { AUTH_COOKIE_NAME } from "../lib/consts";
const cookies = new Cookie();

export const GlobalContext = React.createContext({
  user: null,
  loginHandler: () => {},
  getUserFromLocalStorage: () => {},
  subdomainSettings: undefined,
  subDomainSlug: undefined,
  loading: true,
  sessionHandler: () => {},
});

const storeUserInLocalStorage = (userData) => {
  if (!userData) {
    localStorage.removeItem("user");
    return;
  }

  // Only storing what we actually need just now.
  const redactedUserData = {
    id: userData.id,
    email: userData.email,
    activities: userData.activities,
  };

  localStorage.setItem("user", JSON.stringify(redactedUserData));
};

const getUserFromLocalStorage = () => {
  const user = localStorage.getItem("user");

  if (user) {
    return JSON.parse(user);
  }

  return undefined;
};

const GlobalContextProvider = ({ children }) => {
  // undefined = Not set
  // null = No valid subdomain exists
  // Truthy = Subdomain parsed
  const [subdomainSettings, setSubdomainSettings] = useState(null);
  const [subDomainSlug, setSubDomainSlug] = useState(null);
  const [user, setUserData] = useState(getUserFromLocalStorage());

  // value that can be checked by Pages to ensure that any colours or logos
  // are ready before rendering, idea is to stop janky loads
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const populateSubdomainSettings = async () => {
      const subdomainSlug = extractOrganisationSubdomainFromURL(
        window.location
      );

      if (subdomainSlug) {
        try {
          const settings = await settingsService.getOrganisationSettings(
            subdomainSlug
          );

          setSubDomainSlug(subdomainSlug);
          setSubdomainSettings(settings);
          setLoading(false);
        } catch (error) {
          // Redirect to the main TOL page when any errors (404s, 500s etc) occur.
          console.log(error);

          window.location.replace(
            process.env.REACT_APP_UNBRANDED_APP_URL + "/login"
          );
        }
      } else {
        setLoading(false);
      }
    };

    populateSubdomainSettings();
  }, []);

  const logoutHandler = async () => {
    storeUserInLocalStorage(undefined);

    await cookies.remove(AUTH_COOKIE_NAME);

    setUserData(null);

    window.location.replace("/login");
  };

  // login handler, utilised by the login screen.
  const loginHandler = async (email, password, domain) => {
    // Clear up any existing sessions before attempting to login
    storeUserInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);
    setUserData(null);

    const response = await authenticationService.login(email, password, domain);
    await cookies.set(AUTH_COOKIE_NAME, response.data.token, { path: "/" });

    const userData = {
      id: response.data.id,
      email: response.data.email,
      activities: response.data.activities,
    };

    setUserData(userData);
    storeUserInLocalStorage(userData);

    return userData;
  };

  const sessionHandler = async () => {
    try {
      const resp = await usersService.session();
      await cookies.remove(AUTH_COOKIE_NAME);
      await cookies.set(AUTH_COOKIE_NAME, resp.data.token, { path: "/" });
    } catch (error) {
      console.log(error);
      logoutHandler();
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        user,
        subdomainSettings,
        subDomainSlug,
        loading,
        sessionHandler,
        setLoading,
      }}
    >
      {!loading && children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
};

export default GlobalContextProvider;
