import React from "react";
import PropTypes from "prop-types";
import Icon from "../../core/icon/Icon";
import "./PasswordRulesDescription.scss";
export const PasswordRulesDescription = ({ className }) => {
  return (
    <div className={`password-rules-description ${className}`}>
      <Icon type="Info" />
      <div>
        Your password must contain at least :-
        <ul>
          <li>1 lowercase letter</li>
          <li>1 uppercase letter</li>
          <li>1 digit</li>
          <li>8 characters</li>
        </ul>
      </div>
    </div>
  );
};

PasswordRulesDescription.defaultProps = {
  className: "",
};

PasswordRulesDescription.propTypes = {
  className: PropTypes.string,
};
