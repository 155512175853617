import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import { NavLink } from "./NavLink";
import DownCaret from "../../../assets/down-caret.svg";
import Chevron from "../../../components/core/icon/icons/Chevron";

export const NavAccordion = ({ label, icon, subLinks, active, navToggler }) => {
  // store the index of the active accordion in state
  // it will always be either "0" for open
  // or null for closed
  const [activeKey, updateActiveKey] = useState("0");

  // fires every time we change path, allows the nav to be reset on path change
  // prop created Nav.jsx and passed down through Links
  useEffect(() => {
    updateActiveKey(null);
  }, [navToggler]);

  const Icon = icon;
  return (
    <Accordion activeKey={activeKey}>
      <div
        className={`nav__toggle-wrapper ${active && "active"} ${
          activeKey === "0" ? "open" : ""
        }`}
      >
        <div className="container-fluid">
          <Accordion.Toggle
            className={`nav__top-level-link`}
            variant="link"
            eventKey="0"
            onClick={() => updateActiveKey(!activeKey ? "0" : null)}
          >
            {icon && <span className="nav__link-icon">{<Icon />}</span>}
            <span>{label}</span>
            <div className="nav__accordion-caret ">
              <Chevron />
            </div>
          </Accordion.Toggle>
        </div>
      </div>
      <Accordion.Collapse eventKey="0">
        <div>
          {subLinks.map((link) => (
            <NavLink
              key={link.id}
              className={"nav__sub-link container-fluid"}
              activities={link.activities}
              to={link.to}
              label={link.label}
              cypressId={link.cypressId}
            />
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};

NavAccordion.propTypes = {
  navToggler: PropTypes.bool,
  active: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.func,
  subLinks: PropTypes.arrayOf(PropTypes.shape({})),
};
