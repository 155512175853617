import React from "react";
import "./App.scss";
import { Routes } from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalContextProvider from "./context/GlobalContext";
import { Manifest } from "./components/context-aware/Manifest/Manifest";
import { InstallPromptForApple } from "./components/core/pwa/install-pwa/InstallPwa";

function App() {
  return (
    <GlobalContextProvider>
      <Manifest />
      <Router>
        <Routes />
      </Router>
      <InstallPromptForApple />
    </GlobalContextProvider>
  );
}

export default App;
