import React, { useContext } from "react";
import { css } from "@emotion/react/macro";
import { GlobalContext } from "../../../context/GlobalContext";

function withButtonSubdomainStyling(WrappedComponent) {
  const Button = (props) => {
    const { subdomainSettings } = useContext(GlobalContext);

    let style;

    if (subdomainSettings && subdomainSettings.primaryColour) {
      style = css`
        border: 1px solid ${subdomainSettings.primaryColour};
        background-color: ${subdomainSettings.primaryColour};
        &:hover {
          color: ${subdomainSettings.primaryColour};
          background-color: white;
        }
      `;
    }

    return <WrappedComponent {...props} css={style} />;
  };

  return Button;
}

export default withButtonSubdomainStyling;
