import React from "react";
import FooterLogo from "../../../assets/vidatec-logo.png";
import Button from "../../../components/core/button/Button";
import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";

export const InvalidPasswordResetTokenPage = () => (
  <RegistrationResponseWidget
    heading="Reset Password"
    bodyText="The reset password token is either invalid, or has been used before."
    href={"/login"}
  />
);
