import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { TextInputField } from "../../core/form/TextInputField";
import { Alert } from "../../core/alert/Alert";
import Button from "../../core/button/Button";
import { useForm } from "react-hook-form";
import { InstallPwa } from "../../../components/core/pwa/install-pwa/InstallPwa";
import "./LoginForm.scss";

export const LoginForm = ({ onComplete, loginError }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Form
      className="components__login_form"
      onSubmit={handleSubmit(onComplete)}
    >
      <TextInputField
        type="email"
        name="email"
        label="Email Address"
        placeholder="Enter email address"
        className="input-field-row"
        data-cy="email-input"
        ref={register({
          required: true,
        })}
        errors={errors}
      />

      <TextInputField
        type="password"
        name="password"
        label="Password"
        placeholder="Enter password"
        className="input-field-row"
        data-cy="password-input"
        ref={register({
          required: true,
        })}
        errors={errors}
      />

      <InstallPwa
        className="login-form__pwa-field"
        label="Add to Home Screen"
      />
      <Button
        type="submit"
        className="sign-in-button large primary"
        data-cy="sign-in-button"
      >
        Sign In
      </Button>

      {loginError && (
        <Alert data-cy="login-error" className="alert" variant="danger">
          {loginError}
        </Alert>
      )}
    </Form>
  );
};

LoginForm.defaultProps = {
  loginError: undefined,
};

LoginForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  loginError: PropTypes.string,
};
