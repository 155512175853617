import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { TextInputField } from "../../core/form/TextInputField";
import { Alert } from "../../core/alert/Alert";
import Button from "../../core/button/Button";
import { useForm } from "react-hook-form";

import "./ResetPasswordForm.scss";

export const ResetPasswordForm = ({ onComplete }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Form
      className="components__reset_password_form"
      onSubmit={handleSubmit(onComplete)}
    >
      <TextInputField
        type="email"
        name="email"
        label="Email Address"
        placeholder="Enter email address"
        className="input-field-row"
        data-cy="email-input"
        ref={register({
          required: true,
        })}
        errors={errors}
      />

      <Button
        type="submit"
        className="reset-password-button large primary"
        data-cy="reset-password-button"
      >
        Reset Password
      </Button>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
