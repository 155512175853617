import React, { useEffect, useContext } from "react";
import Nav from "../components/core/nav/Nav";
import { Switch, Route, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { HomePage } from "../pages/home/HomePage";
import { LogoutPage } from "../pages/authentication/logout-page/LogoutPage";
import { SettingsPage } from "../pages/settings/SettingsPage";
import { ChangePasswordPage } from "../pages/settings/change-password/ChangePasswordPage";
import { ChaptersPage } from "../pages/journey/chapters/ChaptersPage";
import { ResourcesPage } from "../pages/journey/resources/ResourcesPage";
import { UpdatesPage } from "../pages/journey/updates/UpdatesPage";
import { SESSION_CHECK_IGNORE_LIST } from "../lib/consts";
const excludePath = Object.values(SESSION_CHECK_IGNORE_LIST);

export const LoggedInRoutes = () => {
  const location = useLocation();
  const { sessionHandler } = useContext(GlobalContext);

  // on logged in routes, we want to check the session token and update it if
  // the user hits a new path, hitting a new path = being active

  useEffect(() => {
    if (excludePath.includes(location.pathname)) return;
    const session = async () => {
      await sessionHandler();
    };

    session();
  }, [location]);

  return (
    <>
      <Nav />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/settings" component={SettingsPage} />
        <Route exact path="/change-password" component={ChangePasswordPage} />
        <Route exact path="/chapters" component={ChaptersPage} />
        <Route exact path="/resources" component={ResourcesPage} />
        <Route exact path="/updates" component={UpdatesPage} />
      </Switch>
    </>
  );
};
