import api from "../../lib/http";

export default {
  getOrganisationSettings: async (organisationSlug) => {
    const result = await api(
      "get",
      "settings/organisation/" + organisationSlug,
      null,
      true,
      false,
      true
    );

    return result.data;
  },
};
