import React from "react";
import PropTypes from "prop-types";
import { PermissionsGated } from "../../context-aware/PermissionsGated/PermissionsGated";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

const Frag = ({ children }) => <> {children} </>;
Frag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const NavLink = ({
  to,
  label,
  activities,
  icon,
  className,
  id,
  wrapper,
  cypressId,
}) => {
  // convert our props into vars that react will read
  const Wrapper = wrapper;
  const Icon = icon;
  return (
    <PermissionsGated activities={activities}>
      <Wrapper>
        <div id={id} className={`nav__link`}>
          <ReactRouterNavLink className={className} to={to}>
            {icon && <span className="nav__link-icon">{<Icon />}</span>}
            <span data-cy={cypressId}>{label}</span>
          </ReactRouterNavLink>
        </div>
      </Wrapper>
    </PermissionsGated>
  );
};

NavLink.defaultProps = {
  wrapper: Frag,
  cypressId: "",
};

NavLink.propTypes = {
  to: PropTypes.string,
  id: PropTypes.string,
  wrapper: PropTypes.func,
  label: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.func,
  className: PropTypes.string,
  cypressId: PropTypes.string,
};
