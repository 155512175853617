import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "./NavLink";
import { NavDropDown } from "./NavDropDown";
import { useLocation } from "react-router-dom";
import { PermissionsGated } from "../../context-aware/PermissionsGated/PermissionsGated";
import _ from "lodash";
import { NavAccordion } from "./NavAccordion";

// helper function too understand whether the component is a top level chump w/ children
// or a big link-loner
const parentCheck = (subsArr, DrawerComponent, NavLinkComponent) => {
  if (subsArr && subsArr.length > 0) return DrawerComponent;
  else return NavLinkComponent;
};

// matches the paths in subLinks array to the current page slug
// helps us chuck an active class on the parent if we want to
// show where the active link is
const isTheActivePageAChild = (subLinksArray, currentPath) => {
  let match = false;
  subLinksArray.forEach((link) => {
    if (link.to === currentPath) match = true;
  });
  return match;
};

export const NavLinks = ({
  to,
  icon,
  activities,
  subLinks,
  label,
  isMobile,
  navToggler,
  cypressId,
}) => {
  const location = useLocation();
  let active = false;
  if (subLinks && subLinks.length > 0) {
    active = isTheActivePageAChild(subLinks, location.pathname);
  }

  return (
    <PermissionsGated activities={activities}>
      {isMobile
        ? parentCheck(
            subLinks,
            <NavAccordion
              to={to}
              icon={icon}
              activities={activities}
              subLinks={subLinks}
              label={label}
              active={active}
              navToggler={navToggler}
            />,
            <NavLink
              to={to}
              icon={icon}
              activities={activities}
              label={label}
              className="nav__top-level-link --mobile"
              cypressId={cypressId}
            />
          )
        : parentCheck(
            subLinks,
            <NavDropDown
              to={to}
              icon={icon}
              activities={activities}
              subLinks={subLinks}
              label={label}
              active={active}
              navToggler={navToggler}
              cypressId={cypressId}
            />,
            <NavLink
              to={to}
              icon={icon}
              activities={activities}
              label={label}
              className="nav__top-level-link"
              cypressId={cypressId}
            />
          )}
    </PermissionsGated>
  );
};

NavLinks.defaultProps = {
  cypressId: "",
};

NavLinks.propTypes = {
  isMobile: PropTypes.bool,
  to: PropTypes.string,
  label: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.func,
  subLinks: PropTypes.arrayOf(PropTypes.shape({})),
  navToggler: PropTypes.bool,
  cypressId: PropTypes.string,
};
