import React from "react";

const MenuJourney = () => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"Menu_Journey"}</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.164706 0 0 0 0 0.258824 0 0 0 0 0.400000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g id="Menu_Journey" fill="none" fillRule="evenodd">
        <g fill="#2A4266">
          <path
            d="M13 2c6.627 0 12 5.373 12 12a11.99 11.99 0 01-5.571 10.134 1 1 0 01-1.515-.638.989.989 0 01-.018-.476 4.99 4.99 0 00-2.277-5.28 1 1 0 01-.106-1.628 4 4 0 10-5.027-.003 1 1 0 01-.104 1.63 4.995 4.995 0 00-2.274 5.302c.043.2.022.4-.05.578a1 1 0 01-1.483.518A11.99 11.99 0 011 14C1 7.373 6.373 2 13 2zm0 2C7.477 4 3 8.477 3 14a9.979 9.979 0 003.047 7.189 6.985 6.985 0 012.228-4.355l.065-.057-.049-.059a5.975 5.975 0 01-1.285-3.444L7 13a6 6 0 1110.71 3.718l-.05.06.065.057a6.985 6.985 0 012.228 4.353A9.978 9.978 0 0023 14c0-5.523-4.477-10-10-10z"
            fill="#2A4266"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
};

export default MenuJourney;
