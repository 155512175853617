import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { NewPasswordForm } from "../../../components/authentication/new-password-form/NewPasswordForm";
import qs from "qs";
import registrationService from "../../../services/registration/registration-service";
import FooterLogo from "../../../assets/tol-logo-grey-with-title.png";
import HttpStatus from "http-status-codes";
import ErrorCodes from "../../../lib/error-codes";
import "./CompleteRegistrationPage.scss";
import { Logo } from "../../../components/context-aware/logo/Logo";

export const CompleteRegistrationPage = () => {
  const [token, setToken] = useState("false");
  const history = useHistory();

  useEffect(() => {
    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (!qsToken.token) {
        history.push("/invalid-registration-token");
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const { valid } = await registrationService.validateToken(qsToken.token);

      if (!valid) {
        history.push("/invalid-registration-token");
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(qsToken.token);
    }

    validateAndSetToken();
  }, [setToken, location.search]);

  const displayValidationError = (validationError) => {
    if (
      validationError.data.field === "password" &&
      validationError.data.code === ErrorCodes.PASSWORD_DOESNT_MEET_REQUIREMENTS
    ) {
      // If the error is one we are expecting, display the message accordingly.
      return "The password does not meet the required complexity requirements.";
    } else {
      // If the error is not one we are expecting, then bubble the error up the stack
      throw validationError;
    }
  };

  const setPasswordHandler = async (passwordInputState) => {
    let regResponse;

    try {
      regResponse = await registrationService.completeRegistration(
        token,
        passwordInputState
      );
    } catch (registrationError) {
      if (registrationError.statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
        // If the error is a validation error, the display a message accordingly
        return displayValidationError(registrationError);
      } else {
        // If the error is not a validation error, then bubble the error up the stack
        throw registrationError;
      }
    }

    // Password setting successful, redirect to registration completed.
    return history.push(
      `/registration-completed?signInLink=${regResponse.signInLink}`
    );
  };

  return (
    <div className="container">
      <div className="complete-registration-page__wrapper">
        <div className="complete-registration-page__form">
          <Logo />
          <h5 data-cy="header" className="complete-registraton-page__heading">
            Complete Registration
          </h5>
          <p>
            Please enter and confirm your new password to complete registration.
          </p>
          <NewPasswordForm
            token={token}
            onComplete={setPasswordHandler}
            submitButtonText={"Complete Registration"}
          />
        </div>
        <img
          className="complete-registration__footer-logo"
          src={FooterLogo}
          alt="talent on leave logo in grey with company name"
        />
      </div>
    </div>
  );
};
