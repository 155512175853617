/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "../../button/Button";
import WithInternalLinkButtonSubdomainStyling from "./WithInternalLinkButtonSubdomainStyling";

import "./InternalLinkButton.scss";

const InternalLinkButton = ({
  children,
  to,
  "data-cy": cypressId,
  className,
  size,
  colour,
  css,
}) => {
  return (
    <Link
      className={`components__internal-link-button btn__basic ${className} ${size} ${colour}`}
      data-cy={cypressId}
      css={css}
      to={to}
    >
      {children}
    </Link>
  );
};

InternalLinkButton.defaultProps = {
  "data-cy": "",
  size: "",
  className: "",
  colour: "primary",
};

InternalLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  "data-cy": PropTypes.string,
  size: PropTypes.string,
  colour: PropTypes.string,
  className: PropTypes.string,
  css: PropTypes.shape({}),
};

export default WithInternalLinkButtonSubdomainStyling(InternalLinkButton);
