import React, { useContext } from "react";
import { css } from "@emotion/react/macro";
import { GlobalContext } from "../../../context/GlobalContext";

function withNavSubdomainStyling(WrappedComponent) {
  const Nav = (props) => {
    const { subdomainSettings } = useContext(GlobalContext);

    let style;

    if (subdomainSettings && subdomainSettings.primaryColour) {
      style = css`
        .active::before {
          border-color: ${subdomainSettings.primaryColour};
        }

        .nav__link-icon {
          svg {
            g {
              fill: ${subdomainSettings.primaryColour};
            }
          }
        }

        .nav__pwa-button,
        .nav__mobile-pwa-button {
          svg {
            fill: ${subdomainSettings.primaryColour};
          }
        }
      `;
    }

    return <WrappedComponent {...props} css={style} />;
  };

  return Nav;
}

export default withNavSubdomainStyling;
