import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Brand from "../../../assets/tol-logo.svg";
import BrandWithTitle from "../../../assets/tol-logo-with-title.svg";
import { GlobalContext } from "../../../context/GlobalContext";

export const Logo = ({ defaultWithTitle }) => {
  const [brand, updateBrand] = useState(undefined);
  const { subdomainSettings } = useContext(GlobalContext);

  useEffect(() => {
    if (subdomainSettings && subdomainSettings.logoUrl) {
      updateBrand(subdomainSettings.logoUrl);
    } else {
      if (defaultWithTitle) {
        updateBrand(BrandWithTitle);
      } else {
        updateBrand(Brand);
      }
    }
  }, []);

  return <img src={brand} alt="Logo" />;
};

Logo.defaultProps = {
  defaultWithTitle: true,
};

Logo.propTypes = {
  defaultWithTitle: PropTypes.bool,
};
