import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { NewPasswordForm } from "../../../components/authentication/new-password-form/NewPasswordForm";
import authenticationService from "../../../services/authentication/authentication-service";
import qs from "qs";
import { Logo } from "../../../components/context-aware/logo/Logo";
import FooterImg from "../../../assets/tol-logo-grey-with-title.png";
import "./NewPasswordPage.scss";
import HttpStatus from "http-status-codes";
import ErrorCodes from "../../../lib/error-codes";

export const NewPasswordPage = () => {
  const [token, setToken] = useState("false");
  const history = useHistory();

  useEffect(() => {

    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const parsedQs = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (!parsedQs.token) {
        history.push("/invalid-password-reset-token");
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const { valid } = await authenticationService.validatePasswordResetToken(
        parsedQs.token
      );

      if (!valid) {
        history.push("/invalid-password-reset-token");
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(parsedQs.token);
    }

    validateAndSetToken();
  }, [setToken, location.search]);

  const displayValidationError = (validationError) => {
    if (
      validationError.data.field === "password" &&
      validationError.data.code === ErrorCodes.PASSWORD_DOESNT_MEET_REQUIREMENTS
    ) {
      // If the error is one we are expecting, display the message accordingly.
      return "The password does not meet the required complexity requirements.";
    } else {
      // If the error is not one we are expecting, then bubble the error up the stack
      throw validationError;
    }
  };

  const newPasswordSet = async (password) => {
    // Redirect to invalid token page if the token is not valid
    const { valid } = await authenticationService.validatePasswordResetToken(
      token
    );

    if (!valid) {
      return history.push("/invalid-password-reset-token");
    }

    try {
      const newPasswordResponse = await authenticationService.newPassword(
        token,
        password
      );

      return history.push(
        `/password-reset-success?signInLink=${newPasswordResponse.signInLink}`
      );
    } catch (registrationError) {
      if (registrationError.statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
        // If the error is a validation error, the display a message accordingly
        return displayValidationError(registrationError);
      } else {
        // If the error is not a validation error, then bubble the error up the stack
        throw registrationError;
      }
    }
  };

  return (
    <div className="pages__new-password-page container-fluid">
      <div className="logo-wrapper">
        <Logo />
      </div>
      <div className="header-and-subtext">
        <h5 data-cy="header">Reset Your Password</h5>
        <p>Please enter and confirm your new password below.</p>
      </div>

      <NewPasswordForm
        onComplete={newPasswordSet}
        submitButtonText={"Reset Password"}
      />
      <div className="new-password__footer-logo-container">
        <img
          src={FooterImg}
          className="new-password__footer-logo"
          alt="tol logo with log"
        />
      </div>
    </div>
  );
};
