import React, { useContext } from "react";
import { css } from "@emotion/react/macro";
import { GlobalContext } from "../../../../context/GlobalContext";

function withInternalLinkSubdomainStyling(WrappedComponent) {
  const Link = (props) => {
    const { subdomainSettings } = useContext(GlobalContext);

    let style;

    if (subdomainSettings && subdomainSettings.primaryColour) {
      style = css`
        color: ${subdomainSettings.primaryColour};

        &:hover {
          color: ${subdomainSettings.primaryColour};
        }
      `;
    }

    return <WrappedComponent {...props} css={style} />;
  };

  return Link;
}

export default withInternalLinkSubdomainStyling;
