import React from "react";

import FooterImg from "../../../assets/tol-logo-grey-with-title.png";
import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-logo-container">
        <img src={FooterImg} className="footer-logo" alt="tol logo with log" />
      </div>
    </div>
  );
};
