/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withInternalLinkSubdomainStyling from "./WithInternalLinkSubdomainStyling";
import "./InternalLink.scss";

const InternalLink = ({
  children,
  to,
  "data-cy": cypressId,
  className,
  css,
}) => {
  return (
    <Link
      css={css}
      to={to}
      className={`components__internal-link ${className}`}
      data-cy={cypressId}
    >
      {children}
    </Link>
  );
};

InternalLink.defaultProps = {
  "data-cy": "",
  children: undefined,
  className: "",
  css: {},
};

InternalLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  "data-cy": PropTypes.string,
  className: PropTypes.string,
  css: PropTypes.shape({}),
};

export default withInternalLinkSubdomainStyling(InternalLink);
