import api from "../../lib/http";

export default {
  completeRegistration: async (token, password) => {
    const result = await api(
      "post",
      "registration/complete-registration",
      { token, password },
      true
    );
    return result.data;
  },
  validateToken: async (token) => {
    const result = await api(
      "get",
      `registration/validate-token?token=${token}`,
      null,
      true
    );
    return result.data;
  },
};
