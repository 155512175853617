import React from "react";
import FooterLogo from "../../../assets/tol-logo-grey-with-title.png";
import Button from "../../../components/core/button/Button";
import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";

export const InvalidRegistrationTokenPage = () => (
  <RegistrationResponseWidget
    heading="Complete Registration"
    bodyText="The registration token is either invalid, or has been used before."
    href={"/login"}
  />
);
