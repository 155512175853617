/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "../../button/Button";

import "./ExternalLinkButton.scss";
import withExternalLinkButtonSubdomainStyling from "./WithExternalLinkButtonSubdomainStyling";

const ExternalLinkButton = ({
  children,
  href,
  "data-cy": cypressId,
  className,
  size,
  colour,
  css,
}) => {
  return (
    <Button
      className={`components__external-link-button ${className}`}
      size={size}
      colour={colour}
      css={css}
    >
      <a data-cy={cypressId} href={href}>
        {children}
      </a>
    </Button>
  );
};

ExternalLinkButton.defaultProps = {
  "data-cy": "",
  size: "",
  className: "",
  href: "/",
  colour: "primary",
  css: {},
};

ExternalLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  "data-cy": PropTypes.string,
  size: PropTypes.string,
  colour: PropTypes.string,
  className: PropTypes.string,
  css: PropTypes.shape({}),
};

export default withExternalLinkButtonSubdomainStyling(ExternalLinkButton);
