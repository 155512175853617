module.exports.activities = {
  DUMMY_ADMIN_ACTIVITY: "DUMMY_ADMIN_ACTIVITY",
  DUMMY_HR_ACTIVITY: "DUMMY_HR_ACTIVITY",
  DUMMY_LINE_MANAGER_ACTIVITY: "DUMMY_LINE_MANAGER_ACTIVITY",
  DUMMY_ACTIVITY: "DUMMY_ACTIVITY",
  DUMMY_ACTIVITY_DISABLED: "DUMMY_ACTIVITY_DISABLED_ORG",
};

module.exports.doesUserHaveActivities = (
  userActivityList,
  requiredActivities
) => {
  if (!userActivityList || !Array.isArray(userActivityList)) {
    return false;
  }

  let hasActivity = true;
  requiredActivities.forEach((activity) => {
    if (!userActivityList.includes(activity)) {
      hasActivity = false;
    }
  });

  return hasActivity;
};
