import React from "react";

const Pencil = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <title>Edit</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Settings"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-326.000000, -230.000000)" id="Edit">
          <g transform="translate(317.000000, 222.000000)">
            <rect id="Rectangle" x="0" y="0" width="42" height="42"></rect>
            <path
              d="M12.017767,20.5251263 L12.017767,23.0251263 L5.76776695,20.5251263 L12.017767,18.0251263 L33.267767,18.0251263 C34.6484788,18.0251263 35.767767,19.1444144 35.767767,20.5251263 C35.767767,21.9058381 34.6484788,23.0251263 33.267767,23.0251263 L14.5068665,23.0251263 L14.5068665,23.0251263"
              id="Path-2"
              stroke="#BD8986"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(20.767767, 20.525126) rotate(-45.000000) translate(-20.767767, -20.525126) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Pencil;
