import React from "react";

import "./PasswordPlaceholder.scss";

const GreyCircle = () => <div className="password-placeholder__grey-circle" />;

export const PasswordPlaceholder = () => (
  <>
    <GreyCircle />
    <GreyCircle />
    <GreyCircle />
    <GreyCircle />
    <GreyCircle />
    <GreyCircle />
    <GreyCircle />
    <GreyCircle />
  </>
);
