import { css } from "@emotion/react/macro";

const getStyling = (subdomainSettings) => {
  return css`
    path {
      fill: ${subdomainSettings.primaryColour};
    }
  `;
};

export default getStyling;
