import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

import FooterImg from "../../../assets/tol-logo-grey-with-title.png";
import successImg from "../../../assets/email-sent-success.svg";
import Button from "../../../components/core/button/Button";
import InternalLinkButton from "../../../components/core/link/internal-link-button/InternalLinkButton";
import { Logo } from "../../../components/context-aware/logo/Logo";

import authenticationService from "../../../services/authentication/authentication-service";

import "./ForgottenPasswordLinkSentPage.scss";

export const ForgottenPasswordLinkSentPage = (props) => {
  return (
    <div className="pages__forgotten-password-link-page container-fluid">
      <div className="logo-wrapper">
        <Logo />
      </div>

      <div className="success-img-wrapper">
        <img src={successImg} alt="email sent successfully image" />
      </div>

      <div className="header-and-subtext">
        <h5>Password Link Sent</h5>
        <p>
          You should receive an email shortly with a link to reset your
          password.
        </p>
      </div>

      <InternalLinkButton
        className="return-to-sign-in-button"
        data-cy="return-to-sign-in-button"
        size="large"
        colour="primary"
        to="/login"
      >
        Return to Sign In
      </InternalLinkButton>

      <div className="password-link-sent__footer-logo-container">
        <img
          src={FooterImg}
          className="password-link-sent__footer-logo"
          alt="tol logo with log"
        />
      </div>
    </div>
  );
};

ForgottenPasswordLinkSentPage.propTypes = {
  location: PropTypes.object,
};
