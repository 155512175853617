import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Placeholder } from "../../../lib/Icons/Icons";
import "./SettingsField.scss";
import Icon from "../../../components/core/icon/Icon";

export const SettingsField = ({ label, onEditTo, placeholder }) => {
  return (
    <div className="settings-field-wrapper">
      <div className="field-label">{label}</div>
      <div className="placeholder-direction-row">{placeholder}</div>
      <Link to={onEditTo}>
        <Icon type="Pencil" />
      </Link>
    </div>
  );
};

SettingsField.propTypes = {
  label: PropTypes.string.isRequired,
  onEditTo: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};
