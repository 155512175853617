import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { TextInputField } from "../../core/form/TextInputField";
import { Alert } from "../../core/alert/Alert";
import Button from "../../core/button/Button";
import { useForm } from "react-hook-form";
import { PasswordRulesDescription } from "../password-rules-description/PasswordRulesDescrption";

import "./NewPasswordForm.scss";

export const NewPasswordForm = ({
  requireConfirmCurrentPassword,
  onComplete,
  submitButtonText,
  errorText,
}) => {
  const [currentPassword, updateCurrPwInputState] = useState("");
  const [passwordInputState, updatePasswordInputState] = useState("");
  const [pwVerifyInputState, updatePwVerifyInputState] = useState("");
  const [error, showError] = useState("");
  const { register, handleSubmit, errors } = useForm();

  const validateMatchHandler = async () => {
    if (passwordInputState !== pwVerifyInputState) {
      return showError("Please make sure both passwords match.");
    }

    const onCompleteError = await onComplete(
      passwordInputState,
      currentPassword
    );

    if (onCompleteError) {
      showError(onCompleteError);
    }
  };

  const clearError = () => {
    if (error) showError("");
  };

  return (
    <>
      <Form
        className="components__new_password_form"
        onSubmit={handleSubmit(validateMatchHandler)}
      >
        {requireConfirmCurrentPassword && (
          <TextInputField
            type="password"
            name="currentPassword"
            label="Current Password"
            value={currentPassword}
            onChange={(e) => {
              clearError();
              updateCurrPwInputState(e.target.value);
            }}
            placeholder="Enter current password"
            className="input-field-row"
            data-cy="curr-password-input"
            controlled
            ref={register({
              required: true,
            })}
            errors={errors}
          />
        )}

        <TextInputField
          type="password"
          name="password"
          label="New Password"
          value={passwordInputState}
          onChange={(e) => {
            clearError();
            updatePasswordInputState(e.target.value);
          }}
          placeholder="Enter new password"
          className="input-field-row"
          data-cy="password-input"
          controlled
          ref={register({
            required: true,
          })}
          errors={errors}
        />
        <PasswordRulesDescription className="components__new_password_form__password-details" />
        <TextInputField
          type="password"
          name="password_match"
          label="Confirm New Password"
          value={pwVerifyInputState}
          onChange={(e) => {
            clearError();
            updatePwVerifyInputState(e.target.value);
          }}
          placeholder="Confirm new password"
          className="input-field-row"
          data-cy="password-confirm-input"
          controlled
          ref={register({
            required: true,
          })}
          errors={errors}
        />
        <Button
          type="submit"
          className="reset-password-button large primary"
          data-cy="reset-password-button"
        >
          {submitButtonText}
        </Button>
        {(error || errorText) && (
          <div className="input-field-row">
            <Alert data-cy="new-password-form-error" variant="danger">
              {error || errorText}
            </Alert>
          </div>
        )}
      </Form>
    </>
  );
};

NewPasswordForm.defaultProps = {
  submitButtonText: "Reset Password",
};

NewPasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  requireConfirmCurrentPassword: PropTypes.bool,
  errorText: PropTypes.string,
};
