import React from "react";
import PropTypes from "prop-types";

import "./FormError.scss";

const FormError = ({ message }) => (
  <div className="components__form-error">{message}</div>
);

FormError.defaultProps = {
  testId: "",
};

FormError.propTypes = {
  message: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

export default FormError;
