import React, { useContext } from "react";
import logo192 from "../../../assets/tol-logo-pwa-icon-192.png";
import logo512 from "../../../assets/tol-logo-pwa-icon-512.png";
import { GlobalContext } from "../../../context/GlobalContext";

export const Manifest = () => {
  const globalContext = useContext(GlobalContext);
  const { subdomainSettings, subDomainSlug, loading } = globalContext;

  const tolBrandColour = "#bd8986";

  let startUrl = process.env.REACT_APP_APP_DOMAIN;
  let protocol =
    process.env.REACT_APP_APP_DOMAIN.indexOf("localhost") === -1
      ? "https"
      : "http";
  let subdomain = subDomainSlug ? `${subDomainSlug}.` : "";
  let manifestStartUrl = `${protocol}://${subdomain}${startUrl}`;

  const manifest = {
    name:
      subdomainSettings && subdomainSettings.appDisplayName
        ? subdomainSettings.appDisplayName
        : `Talent on Leave`,
    short_name:
      subdomainSettings && subdomainSettings.appDisplayName
        ? subdomainSettings.appDisplayName
        : `Talent on Leave`,
    description:
      "Unlocking maternity and parental leave leadership from career to leave and back again.",
    background_color:
      subdomainSettings && subdomainSettings.primaryColour
        ? subdomainSettings.primaryColour
        : tolBrandColour,
    theme_color:
      subdomainSettings && subdomainSettings.primaryColour
        ? subdomainSettings.primaryColour
        : tolBrandColour,
    icons: [
      {
        src:
          subdomainSettings && subdomainSettings.appIconUrl
            ? subdomainSettings.appIconUrl
            : `${manifestStartUrl}${logo192}`,
        sizes: "192x192",
        type: "image/png",
      },
      {
        src:
          subdomainSettings && subdomainSettings.appIconUrl
            ? subdomainSettings.appIconUrl
            : `${manifestStartUrl}${logo512}`,
        sizes: "512x512",
        type: "image/png",
      },
    ],
    start_url: manifestStartUrl,
    display: "fullscreen",
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  const appleIcon =
    subdomainSettings && subdomainSettings.appIconUrl
      ? subdomainSettings.appIconUrl
      : `${manifestStartUrl}${logo512}`;
  // elements found in public html
  document.querySelector("#dynamicManifest").setAttribute("href", manifestURL);
  // element found in public html
  document.querySelector("#dynamicAppleIcon").setAttribute("href", appleIcon);

  return <></>;
};
