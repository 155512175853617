import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";

export const RegistrationCompletePage = () => {
  const [signInLink, updateSignInLink] = useState("/login");
  const location = useLocation();

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }
  }, [updateSignInLink, location.search]);

  return (
    <RegistrationResponseWidget
      heading="Registration Complete"
      bodyText="Success!"
      href={signInLink}
    />
  );
};
