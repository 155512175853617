import React, { useContext } from "react";

import { GlobalContext } from "../../../context/GlobalContext";

export const LogoutPage = () => {
  const { logout } = useContext(GlobalContext);
  logout();

  return <div>Logging Out ...</div>;
};
